import React from 'react';
import styled from 'styled-components';

// Define a styled component for the tooltip
const TooltipWrapper = styled.div`
    position: absolute;
    top: calc(83% + 5px); /* Position below the copied element */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Center horizontally */
    padding: 5px;
    background-color: #333;
    color: #fff;
    border-radius: 4px;
    font-size: 12px;
    opacity: 0.9;
    @media (max-width: 1028px) {
        left: 45%; 
    }
`;

// Define the Tooltip component
const Tooltip = ({ children }) => {
    return (
        <TooltipWrapper>
            {children}
        </TooltipWrapper>
    );
};

export default Tooltip;