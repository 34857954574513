import * as React from "react";
import styled from "styled-components";

const faqData = [
  {
    question: "What is Indian Desi Cow ($DESI) Coin?",
    answer:
      "$DESI Coin is a unique cryptocurrency that bridges the gap between Indian traditions and digital innovation. It celebrates the cultural significance of the Indian Desi Cow, while providing a modern, decentralized financial tool.",
  },
  {
    question: "What is the purpose of $DESI Coin?",
    answer:
      "$DESI Coin aims to build a community through awareness, with the ultimate goal of reaching a $1 billion dollar market cap brand. This would lead to the establishment of a media arm that fosters the continuous proliferation of our beloved cow culture.",
  },
  {
    question: "How will $DESI Coin sustain long-term?",
    answer:
      "$DESI Coin's sustainability is ensured through a balanced tokenomics model, community engagement, and strategic tax distribution. The allocation for development, buy back, and liquidity supports ongoing growth and stability.",
  },
  {
    question: "Are there any upcoming features?",
    answer:
      "Yes, the team is planning to introduce new features, including community rewards programs, partnerships, and potential integrations to further enhance $DESI Coin's utility and growth.",
  },
  {
    question: "How do I buy $DESI Coin?",
    answer: `$DESI Coin can be purchased directly on Uniswap. Here's how:
      <ol>
        <li>Install a compatible wallet, such as MetaMask.</li>
        <li>Load it with Ethereum (ETH).</li>
        <li>Visit Uniswap and connect your wallet.</li>
        <li>Search for the $DESI Coin contract address and swap your ETH for $DESI Coin.</li>
      </ol>`,
  },
  {
    question: "How is $DESI Coin powered?",
    answer:
      "$DESI Coin is built on the Ethereum network and utilizes Uniswap for trading. This ensures secure, decentralized transactions and robust market operations.",
  },
];

function MyComponent() {
  const handleBuyNowClick = () => {
    window.open("https://app.uniswap.org/swap?exactField=&outputCurrency=0x0e99045438Fa058cd0EfA3e5dBF5E74bEdF4E3F0", '_blank'); // Open the URL in a new tab
  };
  return (
    <FaqSection>
      <BackgroundImage loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/78620ffca6bddee76d799112f29bcbf5aa81aec67455209648deb2458c8497aa?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" alt="Background" />
      <FaqContent>
        <FaqTitle>Have any <span> Questions?</span></FaqTitle>
        {faqData.map((item, index) => (
          <FaqItem key={index}>
            <FaqQuestion>
              <strong>{item.question}</strong>
              <br />
            </FaqQuestion>
            <FaqAnswer dangerouslySetInnerHTML={{ __html: item.answer }} />
          </FaqItem>
        ))}<BuyBtnOutner>
          <BuyNowButton onClick={handleBuyNowClick}>Buy Now</BuyNowButton>
        </BuyBtnOutner>
      </FaqContent>
    </FaqSection>
  );
}

const FaqSection = styled.section`
  position: relative;
  display: flex;
  min-height: 1105px;
  align-items: center;
  font-size: 16px;
  color: #b6b6b6;
  font-weight: 400;
  justify-content: center;
  padding: 69px 60px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const FaqContent = styled.div`
  position: relative;
  display: flex;
  width: 897px;
  max-width: 100%;
  flex-direction: column;
`;

const FaqTitle = styled.h2`
  color: #fff;
  text-align: center;
  align-self: center;
  font: 700 40px "Laila", serif;
  margin-bottom: 20px;
  & span {
    background: linear-gradient(90deg, #006bd6 37%, #e5a98d 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const FaqItem = styled.div`
  border-radius: 9px;
  background-color: #0f1616;
  display: flex;
  margin-top: 13px;
  flex-direction: column;
  justify-content: center;
  padding: 15px 28px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
  @media (max-width: 468px) {
padding: 10px
  }
`;

const FaqQuestion = styled.div`
  font-family: "Laila", serif;
  background: linear-gradient(90deg, #e5a98d 0%, #006bd6 31.86%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 10px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-right: 10px;
  }
`;

const FaqAnswer = styled.span`
  font-size: 15px;
  color: #ffffff;
`;
const BuyBtnOutner = styled.div`
width: 220px; 
background: linear-gradient(to right, #006BD6, #E5A98D);
  margin-top: 60px;
  padding: 1px;
  border-radius: 50px;
  background-color: #000;
  color: #fff;
  font: 500 12px "Laila", serif;
  text-align: center;
  cursor: pointer;
  align-self: center;
 @media (max-width: 1028px) {
 margin-top: 16px;
 }
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
  @media (max-width: 468px) {
    margin: 0px 20px;
    margin-top: 20px;
  }
`;
const BuyNowButton = styled.button`
width: 100%;
  padding: 14px 60px;
  border-radius: 50px;
  background-color: #000;
  color: #fff;
  font: 500 12px "Laila", serif;
  text-align: center;
  cursor: pointer;

  @media (max-width: 991px) {
    max-width: 100%;
    padding: 5px 20px;
  }
`;

export default MyComponent;