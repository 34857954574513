import * as React from "react";

import './App.css';
import HeaderSection from "./components/HeaderSection/HeaderSection";
import HeroSection from "./components/HeroSection/HeroSection";
import HowToBuySection from "./components/HowToBuySection/HowToBuySection";
import AboutCoinSection from './components/AboutCoinSection/AboutCoinSection';
import PoweredbySection from './components/PoweredbySection/PoweredbySection'
import RoadmapSection from './components/RoadmapSection/RoadmapSection';
import TokenomicsSection from './components/TokenomicsSection/TokenomicsSection';
import TaxesSection from './components/TaxesSection/TaxesSection';
import FAQSection from './components/FAQSection/FAQSection'
import FooterSection from './components/FooterSection/FooterSection'

function App() {
  return (
    <div className="mainDiv">
      <HeaderSection />
      <HeroSection />
      <HowToBuySection />
      <AboutCoinSection />
      {/* <PoweredbySection /> */}
      <RoadmapSection />
      <TokenomicsSection />
      <TaxesSection />
      <FAQSection />
      <FooterSection />
    </div>

  );
}
export default App;
