import React, { useState, useEffect } from "react";
import styled from "styled-components";

const roadmapData = [
  {
    year: "Q2 2024",
    title: "Launch and Initial Development",
    items: [
      {
        highlight: "Token Launch:",
        description:
          "$DESI Coin launches on the Ethereum blockchain, offering a new way to bridge Indian traditions with digital innovation.",
      },
      {
        highlight: "Liquidity Pool Setup:",
        description:
          "80% of the token supply is added to liquidity pools, primarily on Uniswap, to ensure stable trading.",
      },
      {
        highlight: "Marketing Campaign:",
        description:
          "A robust marketing strategy is initiated, with 15% of the remaining supply allocated to branding and awareness.",
      },
    ],
  },
  {
    year: "Q3 2024",
    title: "Community Building and Awareness",
    items: [
      {
        highlight: "Community Rewards:",
        description:
          "A rewards program is launched, distributing 5% of the remaining supply to early adopters and community participants.",
      },
      {
        highlight: "Social Media Expansion:",
        description:
          "Further expansion across social media platforms to engage the community and build brand recognition.",
      },
      {
        highlight: "Team Expansion:",
        description:
          "Additional team members are onboarded to drive development, marketing, and community initiatives.",
      },
    ],
  },
  {
    year: "Q4 2024",
    title: "Feature Enhancements and Partnerships",
    items: [
      {
        highlight: "Strategic Partnerships:",
        description:
          "$DESI Coin partners with key industry players to expand its ecosystem and utility.",
      },
      {
        highlight: "New Use Cases:",
        description:
          "Development of new use cases, such as integration with decentralized applications (dApps) and potential retail partnerships.",
      },
      {
        highlight: "Buy Back and Liquidity Boost:",
        description:
          "A portion of tax revenues is used for buy back programs and liquidity additions to support market stability.",
      },
    ],
  },
  {
    year: "2025",
    title: "Growth and Evolution",
    items: [
      {
        highlight: "Brand Expansion:",
        description:
          "$DESI Coin continues to grow as a brand, aiming to reach its $1 billion dollar market cap goal.",
      },
      {
        highlight: "Media Arm Launch:",
        description:
          "As $DESI Coin gains traction, the launch of a media arm is planned, focusing on proliferating the message of Indian cultural heritage.",
      },
      {
        highlight: "Continuous Development:",
        description:
          "Ongoing development and community engagement to ensure $DESI Coin's long-term sustainability and growth.",
      },
    ],
  },
];

const Connector = styled.div`
  background: linear-gradient(90deg, #e5a98d 0%, #006bd6 100%);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
    right: -32px;
    top: 43%;
    @media (max-width: 468px) {
        left: 50%;
        top: -30px;
    }
`;

const ConnectorR = styled.div`
  background: linear-gradient(90deg, #e5a98d 0%, #006bd6 100%);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
    left: -32px;
    top: 43%;
    @media (max-width: 468px) {
        left: 50%;
        top: -30px;
    }
`;

const Divider = styled.div`
  background: #1B3553;
  width: 5px;
  height: 100%px;
`;

const RoadmapBox = styled.div`
position: relative 
`;

const RoadmapBox2 = styled.div`
position: relative;
margin-top: 140px ;
@media (max-width: 468px) {
    margin-top: 40px 
}
`;

function Roadmap() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const gradientDegree = scrollPosition * 0.5;
  const RoadmapItem = ({ data }) => (
    <RoadmapOuter gradientDegree={gradientDegree}>
      <RoadmapItemWrapper>
        <RoadmapItemHeader>
          <RoadmapQuarter>{data.year}</RoadmapQuarter>
          <RoadmapTitle>{data.title}</RoadmapTitle>
        </RoadmapItemHeader>
        <RoadmapItemContent>
          <ul>
            {data.items.map((item, index) => (
              <li key={index}>
                <RoadmapHighlight>{item.highlight}</RoadmapHighlight>
                <RoadmapDescription>{item.description}</RoadmapDescription>
              </li>
            ))}
          </ul>
        </RoadmapItemContent>
      </RoadmapItemWrapper>
    </RoadmapOuter>
  );
  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY;
      setScrollPosition(currentPosition);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <RoadmapContainer>
      <BackgroundImage
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/7287e9fbcc1c0ddca52dcc8627b2f07a282eea961a08f1f05fcb7146b087c0ce?apiKey=4ea074c2172a4f308a4b8ec9272610d2&"
        alt="Background"
      />
      <RoadmapContent>
        <RoadmapHeading>Roadmap</RoadmapHeading>
        <RoadmapGrid>
          <RoadmapColumn>
            <RoadmapBox>
              <RoadmapItem data={roadmapData[0]} />
              <Connector />
            </RoadmapBox>
            <RoadmapBox2>
              <RoadmapItem data={roadmapData[2]} />
              <Connector />
            </RoadmapBox2>
          </RoadmapColumn>
          <Divider />
          <RoadmapColumn>
            <RoadmapItemsWrapper>
              <RoadmapBox>
                <RoadmapItem data={roadmapData[1]} />
                <ConnectorR />
              </RoadmapBox>
              <RoadmapBox2>
                <RoadmapItem data={roadmapData[3]} />
                <ConnectorR />
              </RoadmapBox2>
            </RoadmapItemsWrapper>
          </RoadmapColumn>
        </RoadmapGrid>
      </RoadmapContent>
    </RoadmapContainer>
  );
}

const RoadmapContainer = styled.section`
  position: relative;
  display: flex;
  min-height: 1052px;
  align-items: center;
  justify-content: center;
  padding: 80px 60px;
  @media (max-width: 1028px) {
    padding: 0px 60px 80px;
  }
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const RoadmapContent = styled.div`
  position: relative;
  display: flex;
  margin-top: 23px;
  width: 930px;
  max-width: 100%;
  flex-direction: column;
`;

const RoadmapHeading = styled.h2`
  color: #fff;
  text-align: center;
  align-self: center;
  font: 700 40px "Laila", serif;
`;

const RoadmapGrid = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 59px;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
    margin-top: 40px;
  }
`;

const RoadmapColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

const RoadmapItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 170px;
  flex-grow: 1;
  font-size: 13px;
  color: #9b9b9b;
  font-weight: 500;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;
const RoadmapOuter = styled.div`
background: linear-gradient(${props => props.gradientDegree}deg, #006BD6, #E5A98D);
// background: linear-gradient(272deg, #006BD6, #E5A98D);
font-family: "Laila", serif;
border-radius: 5px;
padding: 1px;

&:not(:first-child) {
  margin-top: 140px;
}

@media (max-width: 991px) {
  max-width: 100%;

  &:not(:first-child) {
    margin-top: 40px;
  }
}
`;

const RoadmapItemWrapper = styled.div`
  font-family: "Laila", serif;
  border-radius: 5px;
  background-color: #000;
  padding: 20px;

 
`;

const RoadmapItemHeader = styled.div`
  margin-bottom: 10px;
`;

const RoadmapQuarter = styled.h3`
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  color: #ffffff;
`;

const RoadmapTitle = styled.h4`
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  color: #ffffff;
`;

const RoadmapItemContent = styled.div`
  font-size: 12px;
  color: #ffffff;
  ul {
    list-style: unset;
    color: #ffffff;
    padding-left: 20px;
    margin: 0;
  }

  li {
    margin-bottom: 10px;
  }
`;

const RoadmapHighlight = styled.span`
  color: rgba(237, 237, 237, 1);
`;

const RoadmapDescription = styled.span`
  color: rgba(155, 155, 155, 1);
`;

export default Roadmap;