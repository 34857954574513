import React from "react";
import styled from "styled-components";

const HowToBuySection = () => {
  const steps = [
    {
      text: "Visit Uniswap:",
      description:
        "Navigate to Uniswap and connect your wallet by clicking \"Connect Wallet.\" Follow the prompts to establish a connection.",
    },
    {
      text: "Select the Pair:",
      description:
        "In the Uniswap interface, search for \"$DESI\" in the token field, and ensure you select the correct token. If necessary, enter the contract address manually.",
    },
    {
      text: "Enter the Amount:",
      description:
        "Specify the amount of ETH you want to swap for $DESI Coin. The interface will automatically calculate the equivalent amount of $DESI based on the current exchange rate.",
    },
    {
      text: "Review and Confirm:",
      description:
        "Double-check all details, including the amount, exchange rate, and fees. Once satisfied, click \"Swap\" and confirm the transaction in your wallet.",
    },
  ];
  
  const handleBuyNowClick = () => {
    window.open("https://app.uniswap.org/swap?exactField=&outputCurrency=0x0e99045438Fa058cd0EfA3e5dBF5E74bEdF4E3F0", '_blank'); // Open the URL in a new tab
  };

  return (
    <HowToBuyWrapper>
      <BackgroundImage src="https://cdn.builder.io/api/v1/image/assets/TEMP/31e316b79bcd96020349cbeafc32f037476c906b09d716c32d918b0f5d473e84?apiKey=df2e9b94f577479d8c34b9640987d692&" alt="Background" />
      <ContentWrapper>
        <ContentGrid>
          <TextColumn>
            <TextContent>
              <Title>How to <span> Buy $DESI Coin</span></Title>
              <Subtitle>
                Ready to join the movement?
                <br />
                Here's a simple guide to purchasing Indian Desi Cow Coin ($DESI)
                through
              </Subtitle>
              <Heading>Uniswap:</Heading>
              <StepList>
                {steps.map((step, index) => (
                  <StepItem key={index}>
                    <StepText>{step.text}</StepText> {step.description}
                  </StepItem>
                ))}
              </StepList>
              {/* <Note>
                If you can't connect your wallet you just send Sol to
                xxxxxxxcxxxxxxxxxxxx and our tokens would be airdropped to the
                sending wallet. NB: Don't use CEX to send Sol! Use your own
                wallet.
              </Note> */}
              <BuyBtnOutner>
              <BuyButton onClick={handleBuyNowClick}>Buy Now</BuyButton>
              </BuyBtnOutner>
            </TextContent>
          </TextColumn>
          <ImageColumn>
            <Image src="https://cdn.builder.io/api/v1/image/assets/TEMP/c372ea8118bcafdbfe3dea23c1b6fcf18bdf92a88a313d3010f51f68aa8cec69?apiKey=df2e9b94f577479d8c34b9640987d692&" alt="Illustration" />
          </ImageColumn>
        </ContentGrid>
      </ContentWrapper>
    </HowToBuyWrapper>
  );
};

const HowToBuyWrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 750px;
  // padding: 76px 60px;
  overflow: hidden;
  width: 100%;

  @media (max-width: 991px) {
    padding: 0 20px;
    max-width: 100%;
  }
  @media (max-width: 468px) {
    padding: 0px;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1017px;
  margin-top: 26px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ContentGrid = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
`;

const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 0;
  line-height: normal;
  @media (max-width: 1028px) {
    width: 50%;
  }
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const TextContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: #b6b6b6;
  font-weight: 400;
  @media (max-width: 1028px) {
padding-left: 60px
  }
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 27px;
  }
  @media (max-width: 469px) {
    padding-left: 0px
  }
`;

const Title = styled.h2`
 
  font: 700 40px "Laila", serif;
color: #ffffff;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  @media (max-width: 468px) {
    padding: 0px 20px;
    margin: 0px;
  }
  & span {
    background: linear-gradient(90deg, #006bd6 37%, #e5a98d 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const Subtitle = styled.p`
letter-spacing: 0.5px;
line-height: 22px!important;

  margin-top: 18px;
  font: 16px "Laila", serif;
  color: rgba(255, 255, 255, 1);

  @media (max-width: 1028px) {
    margin-top: 18px;
  }
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
  @media (max-width: 468px) {
    padding: 0px 20px
  }
`;

const Heading = styled.h3`
  margin-top: 28px;
  font: 600 16px "Laila", serif;

  @media (max-width: 1028px) {
    margin-top: 18px;
    margin-bottom: 0px
  }
  @media (max-width: 991px) {
    max-width: 100%;
  }
  @media (max-width: 468px) {
    padding: 0px 20px
  }
`;

const StepList = styled.ul`
  margin-top: 21px;
  font: 13px "Laila", serif;
  letter-spacing: 0.5px;
  line-height: 18px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  @media (max-width: 468px) {
    padding: 0px 30px
  }
`;

const StepItem = styled.li`
letter-spacing: 0.5px;
line-height: 18px;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 10px;
`;

const StepText = styled.span`
letter-spacing: 0.5px;
line-height: 18px;
  color: rgba(255, 255, 255, 1);
`;

const Note = styled.p`
letter-spacing: 0.5px;
line-height: 18px!important;
  margin-top: 25px;
  font: 13px "Laila", serif;

  @media (max-width: 991px) {
    max-width: 100%;
  }
  @media (max-width: 468px) {
    padding: 0px 20px
  }
`;
const BuyBtnOutner = styled.div`
width: 220px; 
background: linear-gradient(to right, #006BD6, #E5A98D);
  margin-top: 35px;
  padding: 1px;
  border-radius: 50px;
  background-color: #000;
  color: #fff;
  font: 500 12px "Laila", serif;
  text-align: center;
  cursor: pointer;

 @media (max-width: 1028px) {
 margin-top: 16px;
 }
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
  @media (max-width: 468px) {
    margin: 0px 20px;
    margin-top: 20px;
  }
`;

const BuyButton = styled.button`
width: 100%;
  padding: 14px 60px;
  border-radius: 50px;
  background-color: #000;
  color: #fff;
  font: 500 12px "Laila", serif;
  text-align: center;
  cursor: pointer;

  @media (max-width: 991px) {
    max-width: 100%;
    padding: 5px 20px;
  }
`;

const ImageColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 20px;
  line-height: normal;
  @media (max-width: 1028px) {
    width: 50%;
    margin-left: 0px;
  }
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 100%;
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  align-self: stretch;
  margin: auto 0;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

export default HowToBuySection;