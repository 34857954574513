import React, { useState, useEffect } from "react";
import styled from "styled-components";

const images = [
  { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/856e9ae5b54f4bd8e14252cc5211f1f6a086628077c5bf9bb002b9bce757cb55?apiKey=4ea074c2172a4f308a4b8ec9272610d2&", alt: "Image 1" },
  { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/d6f47398a88e69973401b38b4c74e2aa6b25898adf6a849e2bee3fe851c81fa6?apiKey=4ea074c2172a4f308a4b8ec9272610d2&", alt: "Image 2" },
  { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/7dc8e4ad3addca728b18173d0afce80449c9ffe55f2af93f68dcba118c3c81a9?apiKey=4ea074c2172a4f308a4b8ec9272610d2&", alt: "Image 3" },
  { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/ad23014618d5e1fd970b0d1a1d1cbf14a5c03d23569409eaa2030309e533d61e?apiKey=4ea074c2172a4f308a4b8ec9272610d2&", alt: "Image 4" },
  { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/03cc8c62edaa586152d60005b1fcb425f83ec1fb3c90411271d209cce6cee72f?apiKey=4ea074c2172a4f308a4b8ec9272610d2&", alt: "Image 5" },
  { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/4089109fb2883be29185471aca3fcff06bd121e660ec76ad18c79c4ff32ec9a1?apiKey=4ea074c2172a4f308a4b8ec9272610d2&", alt: "Image 6" },
  { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/e74fb51c025b6e5cf18f10f154e753232eb8f6ab647e9909eeb382c3b17bbd7b?apiKey=4ea074c2172a4f308a4b8ec9272610d2&", alt: "Image 7" },
  { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/750fe5f33554cb331539a946bb28a94acaef3a333eb7e81a7bc160da3f5850d4?apiKey=4ea074c2172a4f308a4b8ec9272610d2&", alt: "Image 8" },
  { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/8490df459b463fa4af9699ebd7aaecbbbb904edbb65d42b3ef0a8d8dc805b871?apiKey=4ea074c2172a4f308a4b8ec9272610d2&", alt: "Image 9" },
];

const culturalSignificanceData = [
  {
    title: "Cultural Significance:",
    description:
      "$DESI Coin draws its inspiration from India's revered cultural symbol, the cow. The coin's design reflects this heritage, featuring the cow adorned with traditional jewelry, signifying India's respect for its customs.",
  },
];

const communityAndGrowthData = [
  {
    title: "Community and Growth:",
    description:
      "$DESI Coin is more than just a digital asset. It's a movement that fosters a vibrant community, encouraging engagement and growth. Through social media campaigns, contests, and events, $DESI Coin brings together people who share a passion for India's cultural legacy and digital advancements.",
  },
];

function MyComponent() {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY;
      setScrollPosition(currentPosition);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const gradientDegree = scrollPosition * 0.5;

  return (
    <Container>
      <HeroSection>
        {/* <BackgroundImage style={{zIndex: '0'}} src="https://cdn.builder.io/api/v1/image/assets/TEMP/7675149175b2d029be1b43bd9b0fddc0d2ad85ba32eaeee04468348adb1f5ce4?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" alt="" /> */}
        <HeroContent>
          <Title>What is <span> Indian Desi Cow Coin</span></Title>
          <Description>
            Indian Desi Cow Coin ($DESI) is India's first meme coin that
            celebrates the fusion of cultural <br /> heritage and digital
            innovation. It brings together the timeless values of India's
            traditions with <br /> the dynamic world of cryptocurrency, serving
            as a bridge between the past and the future.
          </Description>
          <InfoSection>
            <InfoGrid>
              {culturalSignificanceData.map((item, index) => (
                <InfoCardOuter gradientDegree={gradientDegree}>
                  <InfoCard key={index}>
                    <InfoTitle>{item.title}</InfoTitle>
                    <InfoDescription>{item.description}</InfoDescription>
                  </InfoCard>
                </InfoCardOuter>
              ))}
              {communityAndGrowthData.map((item, index) => (
                <InfoCardOuter gradientDegree={gradientDegree}>
                  <InfoCard key={index}>
                    <InfoTitle>{item.title}</InfoTitle>
                    <InfoDescription>{item.description}</InfoDescription>
                  </InfoCard>
                </InfoCardOuter>
              ))}
            </InfoGrid>
          </InfoSection>
        </HeroContent>
      </HeroSection>
      <PoweredBySection>
        <BackgroundImage1 style={{ zIndex: '1' }} src="/img/cow.png" alt="" />
        {/* <BackgroundImage style={{zIndex: '99'}} src="https://cdn.builder.io/api/v1/image/assets/TEMP/008eff1e3b2874f605d91fa47b168b3fd5f712a5e57f4bae90a0789800869f71?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" alt="" /> */}
        <PoweredByContent>
          <BackgroundImage style={{ zIndex: '0' }} src="https://cdn.builder.io/api/v1/image/assets/TEMP/86834d6f234a552dc7fc20ff6fed8fc445a73815bc5ac85d2e7e1234fd8f1aea?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" alt="" />
          <PoweredByTitle>
            <PoweredByTitleText>$DESI Coin is Powered by</PoweredByTitleText>
            <ImageGrid>
              <ImageRow>
                {images.slice(0, 5).map((image, index) => (
                  <ImageItem key={index} src={image.src} alt={image.alt} />
                ))}
              </ImageRow>
              <ImageRow>
                {images.slice(5, 9).map((image, index) => (
                  <ImageItem key={index} src={image.src} alt={image.alt} />
                ))}
              </ImageRow>
            </ImageGrid>
          </PoweredByTitle>
          {/* <ImageItem
                        src={images[8].src}
                        alt={images[8].alt}
                        className="standalone-image"
                    /> */}
        </PoweredByContent>
      </PoweredBySection>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  z-index: 10;
  // min-height: 1003px;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 80px 0px 10px;
  @media (max-width: 1028px) {
    min-height: unset!important;
    padding: 80px 0px 0px;
  }
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
  @media (max-width: 468px) {
    padding: 0px;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
`;

const BackgroundImage1 = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
  @media (max-width: 1028px) {
    height: 100%;
  }
  @media (max-width: 468px) {
    height: 100%;
    margin-top: -15px;
  }
`;

const HeroContent = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  max-width: 998px;
  flex-direction: column;
  align-items: center;
  margin: 0px 0 0px;
  @media (max-width: 1028px) {
    margin: 32px 0 0px;
  }
  @media (max-width: 991px) {
    max-width: 100%;
    margin-bottom: 40px;
  }
`;

const Title = styled.h1`
  color: #fff;
  text-align: center;
  font: 700 40px "Laila", serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  @media (max-width: 468px) {
    padding: 0px 20px
  }
  & span {
    background: linear-gradient(90deg, #006bd6 37%, #e5a98d 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const Description = styled.p`
  color: #b6b6b6;
  text-align: center;
  margin-top: 55px;
  font: 400 16px "Laila", serif;
  @media (max-width: 1028px) {
    margin-top: 15px;
  };
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  };
  @media (max-width: 468px) {
    padding: 0px 20px
  }
`;

const PoweredBySection = styled.section`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-self: center;
  position: relative;
  // min-height: 963px;
  // margin-top: -545px;
  width: 963px;
  max-width: 100%;
  padding-top: 80px;
  align-items: start;
  @media (max-width: 1028px) {
    margin-top: 0px;
    min-height: unset;
  }
  @media (max-width: 991px) {
    margin-top: -200px;
  }
  @media (max-width: 468px) {
    margin-top: 0px;
    padding-top: 0px;
    min-height: unset;
  }
`;

const PoweredByContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  z-index: 10;
  min-height: 450px;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 465px 0 -32px;
  padding: 0px 0px;
  @media (max-width: 1028px) {
  padding: 69px 0px 0px;
  margin: 265px 0 0px;
  }
  @media (max-width: 991px) {
    max-width: 100%;
    margin: 40px 0 10px;
    padding: 0 20px;
  }
  @media (max-width: 468px) {
    margin: 0px;
    padding: 0px
  }
`;

const PoweredByTitle = styled.div`
  border-radius: 200px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.29) 0%,
    rgba(0, 0, 0, 0.32) 70.5%
  );
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  width: fit-content;
  padding: 6px 21px 0;
  position: relative;
  margin-top: 13px;
  gap: 0px;
  z-index: 99999;

  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
    flex-wrap: wrap;
  }
  @media (max-width: 468px) {
    padding: 0px;
    margin-top: 300px
  }
`;

const PoweredByTitleText = styled.h2`
  color: #fff;
  text-align: center;
  align-self: center;
  font: 500 40px "Laila", serif;

  @media (max-width: 991px) {
    max-width: 100%;
  }
  @media (max-width: 468px) {
    font: 20px "Laila", serif;
    margin: 0px;
    padding-bottom: 10px;
  }
`;

const ImageGrid = styled.div`
  display: flex;
  flex-direction: column;
  margin: 25px 0 0 10px;
  @media (max-width: 1028px) {
    margin: 0px;

  }
  @media (max-width: 991px) {
    max-width: 100%;
    margin: 40px 10px 0 0;
  }
  @media (max-width: 468px) {
    margin: 0px;
  }
`;

const ImageRow = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;

  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    padding-right: 20px;
  }
  @media (max-width: 468px) {
    padding-right: 0px;
    gap: 2px;
  }
`;

const ImageItem = styled.img`
  aspect-ratio: 1.92;
  object-fit: contain;
  object-position: center;
  width: 166px;
  max-width: 100%;
  @media (max-width: 1028px) {
    width: 130px;
  }
  @media (max-width: 468px) {
    width: 70px;
  }
  &.standalone-image {
    align-self: start;
    margin-top: 97px;

    @media (max-width: 991px) {
      margin-top: 40px;
    }
  }
`;

const InfoSection = styled.section`
   align-self: stretch;
   margin-top: 36px;

   @media (max-width: 991px) {
     max-width: 100%;
   }
   @media (max-width: 468px) {
    margin-top: 5px;
  }
 `;

const InfoGrid = styled.div`
   display: flex;
      gap: 20px;
      @media (max-width: 1028px) {
        padding: 0px 40px;
      }
   @media (max-width: 991px) {
     flex-direction: column;
     align-items: stretch;
     gap: 0;
   }
 `;

const InfoCardOuter = styled.article`
 display: flex;
 flex-direction: column;
 line-height: normal;
 width: 50%;
 border-radius: 15px;
  background: linear-gradient(${props => props.gradientDegree}deg, #000 2.73%, #E5A98D 166.33%);
//  background: linear-gradient(46deg, #000 , #E5A98D);
 color: #b6b6b6;
 padding: 1px;
 font: 400 16px "Laila", serif;

 @media (max-width: 991px) {
   width: 100%;
   max-width: 100%;
   margin-top: 40px;
  //  padding: 0 20px;
 }
`;

const InfoCard = styled.article`
height: 100%;
   display: flex;
   justify-content: center;
   flex-direction: column;
   line-height: normal;
   border-radius: 15px;
   border: 1px solid #000;
   background: linear-gradient(221deg, #000 2.73%, #1b2432 166.33%);
   color: #b6b6b6;
   padding: 32px 31px;
   font: 400 16px "Laila", serif;

   @media (max-width: 991px) {
     width: 100%;
     max-width: 100%;
   }  
    @media (max-width: 468px) {
      padding: 20px;
    }
 `;

const InfoTitle = styled.h2`
   font-weight: 600;
 `;

const InfoDescription = styled.p`
   margin-top: 10px;
 `;

export default MyComponent;