import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import App from './App';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import TermsCondition from './components/PrivacyPolicy/TermsCondition';
import CookiePolicy from './components/PrivacyPolicy/CookiePolicy';
// import NotFound from './NotFound'; // You can create a NotFound component for handling 404 errors
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Router>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/terms" element={<TermsCondition />} />
      <Route path="/cookie" element={<CookiePolicy />} />
      {/* <Route component={NotFound} /> This will catch any undefined routes */}
    </Routes>
  </Router>
);
