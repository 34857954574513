import * as React from "react";
import styled from "styled-components";
import { Link } from 'react-router-dom';

const socialMediaData = [
  { src: "/img/telegram.png", alt: "Telegram", url: "https://t.me/Desi_Cow" },
  { src: "/img/twitter.png", alt: "Twitter", url: "https://twitter.com/Desi_Cow_" },
  { src: "/img/uniswap.png", alt: "Uniswap", url: "https://app.uniswap.org/swap?exactField=&outputCurrency=0x0e99045438Fa058cd0EfA3e5dBF5E74bEdF4E3F0" },
  { src: "/img/Dextool.png", alt: "Dextool", url: "https://www.dextools.io/app/en/ether/pair-explorer/0x272914a8bf40d24aa4b6e04a1dae12194922bc19?t=1715003709278" },
];

function Footer() {
  const handleSocialLinkClick = (url) => {
    window.open(url, '_blank'); // Open the URL in a new tab
  };

  const handleEmailClick = () => {
    window.location.href = 'mailto:info@desicow.io';
  };
  return (
    <FooterContainer>
      <BackgroundImage loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/d4803a1f2df58fc26b48c3e0c8077ac80e8c37150fb1385be062196ca12f77db?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" alt="Background" />
      <FooterContent>
        <DisclaimerSection>
          <DisclaimerTitle>Disclaimer</DisclaimerTitle>
          <DisclaimerText>
            Cryptocurrency investments carry a high risk of volatility. Be aware
            of the tax implications, as profits may be subject to capital gains
            or other taxes in your jurisdiction. Cryptocurrency regulations can
            vary, so ensure you understand the rules in your area. Conduct
            thorough research and invest only what you can afford to lose.
            <br />
            <br />
            <CopyrightNotice>
              © 2024 $DESI Coin. All rights reserved
            </CopyrightNotice>
          </DisclaimerText>

        </DisclaimerSection>
        <BottomFooterSection>
          <ContactInfo>
            <SocialMediaTitle>Contact</SocialMediaTitle>
            <ContactEmail onClick={handleEmailClick}>info@desicow.io</ContactEmail>
          </ContactInfo>
          <LegalInfo>
            <SocialMediaTitle>Legal Information</SocialMediaTitle>
            <LegalLinks>
              <Link to="/terms">Terms & Condition</Link>
              <Link to="/privacy">Privacy Policy</Link>
              <Link to="/cookie">Cookie Policy</Link>
            </LegalLinks>
          </LegalInfo>
          <SocialMediaSection>
            <SocialMediaTitle>Social Media</SocialMediaTitle>
            <SocialMediaIcons>
              {socialMediaData.map((item, index) => (
                <SocialMediaIcon
                  onClick={() => handleSocialLinkClick(item.url)}
                  key={index}
                  loading="lazy"
                  src={item.src}
                  alt={item.alt}
                />
              ))}
            </SocialMediaIcons>
          </SocialMediaSection>
        </BottomFooterSection>
      </FooterContent>
    </FooterContainer>
  );
}

const BottomFooterSection = styled.footer`
width: 100%;
display: flex;
justify-content: space-between;
margin-top: 100px;
@media (max-width: 468px) {
    margin-top: 0px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px
}
`;

const FooterContainer = styled.footer`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 60px 35px;
  min-height: 543px;
  overflow: hidden;

  @media (max-width: 991px) {
    padding: 0 20px;
  }
  @media (max-width: 468px) {
    padding: 50px 0 0px;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const FooterContent = styled.div`
flex-direction: column;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  max-width: 1026px;
  margin-top: 12px;
  gap: 20px;

  @media (max-width: 991px) {
    flex-wrap: wrap;
    max-width: 100%;
  }
`;

const ContactInfo = styled.div`
  color: #fff;
  text-align: center;
  font: 400 16px "Laila", serif;
  width: 33.33%;

  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const ContactTitle = styled.span`
  font-weight: 700;
`;

const ContactEmail = styled.span`
  font-size: 14px;
  margin-top: 23px;
  cursor: pointer;
`;

const DisclaimerSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  text-align: center;

  @media (max-width: 991px) {
    max-width: 100%;
  }
  @media (max-width: 468px) {
    width: 100%;
  }
`;

const DisclaimerTitle = styled.h2`
  font: 32px "Laila", serif;
`;

const DisclaimerText = styled.p`
  color: #b6b6b6;
  font-family: "Laila", serif;
  align-self: stretch;
  margin-top: 56px;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
  @media (max-width: 468px) {
    margin-top: 0px;
    padding: 0px 20px;
  }
`;

const CopyrightNotice = styled.small`
  display: block;
  margin-top: 1rem;
`;

const LegalInfo = styled.div`
  font-family: "Laila", serif;
  width: 33.33%;
  display: flex;
  flex-direction: column;

  @media (max-width: 991px) {
    margin-top: 40px;
  }
  @media (max-width: 468px) {
    width: 100%;
  }
`;

const LegalLinks = styled.span`
display: flex;
justify-content: space-evenly;
// margin-top: 23px;
  font-size: 14px;
  color: #ffffff;
`;

const LegalLink = styled.span`
  font-size: 12px;
  color: #ffffff;
`;

const SocialMediaSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 33.33%;
  color: #ffffff;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
  @media (max-width: 468px) {
    width: 100%;
  }
`;

const SocialMediaTitle = styled.h3`
  color: #fff;
  text-align: center;
  font: 700 16px "Laila", serif;
  margin-bottom: 15px
`;

const SocialMediaIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
//   margin-top: 23px;
  gap: 20px;
`;

const SocialMediaIcon = styled.img`
cursor: pointer;
  width: 18px;
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  margin-right: 10px;
  // &:first-child {
  //   aspect-ratio: 1.12;
  //   align-self: start;
  // }
  &:nth-child(3) {
    width: 22px;
    aspect-ratio: 1;
  }
  &:nth-child(3) {
    width: 28px;
    aspect-ratio: 1;
  }
`;

export default Footer;