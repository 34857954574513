import * as React from "react";
import styled from "styled-components";

const tokenomicsData = [
  {
    percentage: "80%",
    title: "Liquidity",
    description:
      "This substantial allocation ensures market stability and liquidity, providing a strong foundation for $DESI Coin's trading ecosystem.",
    color: "#006BD6",
  },
  {
    percentage: "15%",
    title: "Marketing and Branding",
    description:
      "This allocation supports promotional efforts and brand-building initiatives, increasing awareness of $DESI Coin and attracting new users.",
    color: "#738AB2",
  },
  {
    percentage: "5%",
    title: "Community Rewards",
    description:
      "This portion will be used to incentivize participation, rewarding community members for their engagement and contributions to $DESI Coin's growth.",
    color: "#E5A98D",
  },
];

function MyComponent() {
  return (
    <TokenomicsSection>
      <BackgroundImage loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/8c192fad04827c1a5603d5c72adae5cc56f0d9314d125c0fa41a6a451b1ebf3d?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" />
      <TokenomicsContent>
        <TokenomicsTitle>Tokenomics</TokenomicsTitle>
        <TokenomicsDescription>
          $DESI Coin's tokenomics are designed to ensure sustainable growth,
          <br />
          incentivize community engagement, and support further development
        </TokenomicsDescription>
        <TokenomicsImage loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/3916fa94867923def049c5ae5f8c717eebd857ffabf5af89d343500232693cc4?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" />
        <TokenomicsTotalSupply>
          Total Supply: 15,000,000,000
          <br />
        </TokenomicsTotalSupply>
        <TokenomicsAllocation>
          {tokenomicsData.map((item, index) => (
            <TokenomicsColumn key={index}>
              <TokenomicsItem>
                <TokenomicsItemHeader>
                  <TokenomicsItemIcon color={item.color} />
                  <TokenomicsItemTitle>{item.title}</TokenomicsItemTitle>
                </TokenomicsItemHeader>
                <TokenomicsItemDescription>
                  {item.description}
                </TokenomicsItemDescription>
              </TokenomicsItem>
            </TokenomicsColumn>
          ))}
        </TokenomicsAllocation>
      </TokenomicsContent>
    </TokenomicsSection>
  );
}

const TokenomicsSection = styled.section`
  position: relative;
  display: flex;
  min-height: 903px;
  align-items: center;
  justify-content: center;
  padding: 77px 60px;
  overflow: hidden;

  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const TokenomicsContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 936px;
  max-width: 100%;
  margin-top: 26px;
`;

const TokenomicsTitle = styled.h2`
  color: #fff;
  font: 700 40px "Laila", serif;
  text-align: center;
`;

const TokenomicsDescription = styled.p`
  color: #b6b6b6;
  font: 400 16px "Laila", serif;
  text-align: center;
  margin-top: 33px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const TokenomicsImage = styled.img`
  width: 394px;
  max-width: 100%;
  margin-top: 34px;
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
`;

const TokenomicsTotalSupply = styled.p`
  color: #fff;
  font: 700 16px "Laila", serif;
  text-align: center;
  margin-top: 10px;
`;

const TokenomicsAllocation = styled.div`
  display: flex;
  gap: 20px;
  align-self: stretch;
  margin-top: 58px;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
    max-width: 100%;
    margin-top: 40px;
  }
`;

const TokenomicsColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
  margin-right: 30px;
  line-height: normal;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

const TokenomicsItem = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: 12px;

  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const TokenomicsItemHeader = styled.div`
  display: flex;
  gap: 11px;
  color: #c9c9c9;
  font-weight: 600;
  text-align: center;
`;

const TokenomicsItemIcon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 7px;
  background-color: ${(props) => props.color};
`;

const TokenomicsItemTitle = styled.span`
  font-family: "Laila", serif;
  flex-grow: 1;
  margin: auto 0;
  display: flex
`;

const TokenomicsItemDescription = styled.p`
  color: #c2c2c2;
  font-family: "Laila", serif;
  font-weight: 400;
  margin-top: 27px;
`;

export default MyComponent