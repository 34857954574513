import React, { useState } from "react";
import styled from "styled-components";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tooltip from './Tooltip';

const heroData = {
  backgroundImage: "https://cdn.builder.io/api/v1/image/assets/TEMP/70a6fb0407486da6a6e2e1e64fbeca2f61a46f605dfe56561c00be7ed27a8b33?apiKey=df2e9b94f577479d8c34b9640987d692&",
  title: "Bridging Tradition with Digital Innovation",
  subtitle: "Indian Desi Cow",
  subtitle1: "Coin",
  description:
    "India's first meme coin celebrating cultural heritage. Merging the timeless values of India's cultural legacy with the cutting-edge world of cryptocurrency. Join a movement that fuses past and future, leading to a vibrant digital journey.",
  ctaButtons: [
    { text: "Buy Now", link: "https://app.uniswap.org/swap?exactField=&outputCurrency=0x0e99045438Fa058cd0EfA3e5dBF5E74bEdF4E3F0" },
    { text: "LitePaper", link: "#" },
  ],
  contractAddress: "0x0e99045438fa058cd0efa3e5dbf5e74bedf4e3f0",
  heroImage: "https://cdn.builder.io/api/v1/image/assets/TEMP/2871f0b0613f713d200409f07e7e37750313eac0c5abed56d29118697f5c1bb5?apiKey=df2e9b94f577479d8c34b9640987d692&",
};

function handleLitePaperClick() {
  // Replace 'path/to/your/local/pdf.pdf' with the path to your local PDF file
  const pdfUrl = '/document/DESILitepaper.pdf';
  window.open(pdfUrl, '_blank');
}

const handleBuyNowClick = () => {
  window.open("https://app.uniswap.org/swap?exactField=&outputCurrency=0x0e99045438Fa058cd0EfA3e5dBF5E74bEdF4E3F0", '_blank'); // Open the URL in a new tab
};

const HeroSection = () => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyAddressClick = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Hide tooltip after 2 seconds
    } catch (error) {
      console.error('Failed to copy:', error);
    }
  };

  const handleAddressClick = (url) => {
    window.open(url, '_blank'); // Open the URL in a new tab
  };

  return (
    <HeroWrapper>
      <HeroBackground src={heroData.backgroundImage} alt="Hero Background" />
      <HeroContent>
        <HeroInfo>
          <HeroTitle>
            {heroData.title}
            <br />
            <Uppercase>{heroData.subtitle}</Uppercase>
            <br />
            {heroData.subtitle1}
            {/* <Uppercase>Coin</Uppercase> */}
          </HeroTitle>
          <HeroDescription>{heroData.description}</HeroDescription>
          <HeroActions>
            {heroData.ctaButtons.map((button, index) => (
              <React.Fragment key={index}>
                {button.text === "Buy Now" ? (
                  <BuyNowButton onClick={handleBuyNowClick}>{button.text}</BuyNowButton>
                ) : (
                  <LitePaperLink onClick={handleLitePaperClick}>{button.text}</LitePaperLink>
                )}
              </React.Fragment>
            ))}
          </HeroActions>
          {isCopied && <Tooltip>Copied!</Tooltip>}
          <ContractAddress  >
            <div onClick={() => handleAddressClick("https://etherscan.io/token/0x0e99045438fa058cd0efa3e5dbf5e74bedf4e3f0")}>
              <BlueText>$DESI :</BlueText> {heroData.contractAddress}</div>
            <div style={{ zIndex: 99999 }} onClick={() => handleCopyAddressClick(heroData.contractAddress)}>
              <img src="/img/copy.png" alt="copy" />
            </div>
          </ContractAddress>
          <ToastContainer />
        </HeroInfo>
        <HeroImageWrapper>
          <HeroImage src={heroData.heroImage} alt="Hero" />
        </HeroImageWrapper>
      </HeroContent>
    </HeroWrapper>
  );
};

const HeroWrapper = styled.section`
  position: relative;
  display: flex;
  backgroundColor: #000;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 50px 0px;
  overflow: hidden;
    font-family: "Laila", serif;
    overflow-x: hidden;
    color: #fff;
    background: linear-gradient(80deg, #000000, #000000, #000000, #000000, #1F0300, #4b2d25, #132438, #202A39);
    /* background: linear-gradient(-88deg, #000000, #1F0300, #613A2F, #825647, #C48D75, #E5A98D, #006BD6, #0560BC, #0B55A2, #104B88, #15406D, #1B3553, #202A39); */
    background-size: 400% 400%;
    -webkit-animation: Gradient 5s ease infinite;
    -moz-animation: Gradient 5s ease infinite;
    animation: Gradient 5s ease infinite;
  
  @-webkit-keyframes Gradient {
    0% {
      background-position: 0% 50%
    }
  
    50% {
      background-position: 100% 50%
    }
  
    100% {
      background-position: 0% 50%
    }
  }
  
  @-moz-keyframes Gradient {
    0% {
      background-position: 0% 50%
    }
  
    50% {
      background-position: 100% 50%
    }
  
    100% {
      background-position: 0% 50%
    }
  }
  
  @keyframes Gradient {
    0% {
      background-position: 0% 50%
    }
  
    50% {
      background-position: 100% 50%
    }
  
    100% {
      background-position: 0% 50%
    }
  }
  @media (max-width: 991px) {
    padding: 0 20px;
  }
  @media (max-width: 468px) {
    padding: 0px;
  }
`;

const HeroBackground = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const HeroContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 1004px;
  display: flex;
  gap: 20px;

  @media (max-width: 991px) {
    flex-direction: column;
    gap: 0;
  }
`;

const HeroInfo = styled.div`
  width: 58%;
  display: flex;
  flex-direction: column;
  color: #fff;
  font-weight: 700;
  margin: auto 0;

  @media (max-width: 1028px) {
    padding-left: 60px
  }
  @media (max-width: 991px) {
    width: 100%;
    margin-top: 40px;
  }
  @media (max-width: 768px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (max-width: 468px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const HeroTitle = styled.h1`
  // text-shadow: 0 4px 7px #000;
  text-transform: uppercase;
  font-family: "Laila", serif;
  font-size: 36px;
  @media (max-width: 468px) {
    font-size: 30px;
    padding: 0px 20px;
    margin: 0px;
  }
`;

const Uppercase = styled.span`
background: linear-gradient(90deg, #006bd6 37%, #e5a98d 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  text-transform: uppercase;
`;

const HeroDescription = styled.p`
  color: #b6b6b6;
  margin-top: 16px;
  font: 500 15px "Laila", serif;
  @media (max-width: 468px) {
    padding: 0px 20px;
  }
`;

const HeroActions = styled.div`
  display: flex;
  align-self: start;
  margin-top: 35px;
  gap: 20px;
  font-size: 12px;
  font-weight: 500;
  @media (max-width: 869px) {
    padding: 0px 20px;
  }
  @media (max-width: 468px) {
    padding: 0px 20px;
  }
`;

const BuyNowButton = styled.button`
  font-family: "Laila", serif;
  border-radius: 19px;
  background: linear-gradient(90deg, #e1a88e 0%, #036bd5 100%);
  justify-content: center;
  padding: 11px 29px;
  border: 0.5px solid #006bd6;
  cursor: pointer;
  color: #ffffff;

  @media (max-width: 991px) {
    padding: 5px 20px;
  }
`;

const LitePaperLink = styled.a`
  font-family: "Laila", serif;
  text-decoration: underline;
  margin: auto 0;
  cursor: pointer;
`;

const ContractAddress = styled.div`
cursor: pointer;
display: flex;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 4px 5px rgba(0, 0, 0, 0.98);
  border-radius: 23.5px;
  border: 1px solid #e5a98d;
  background-color: rgba(0, 0, 0, 0.66);
  align-self: end;
  margin-top: 48px;
  color: #dfdfdf;
  text-align: center;
  justify-content: center;
  padding: 9px 20px;
  align-items: center;
  font: 18px "Laila", serif;
  @media (max-width: 1028px) {
    font: 15px "Laila", serif;
    align-self: start;
  }
  @media (max-width: 991px) {
    margin-top: 40px;
    padding: 3px 20px;
  }
  @media (max-width: 468px) {
    font-size: 12px;
    align-self: center;
    padding: 3px 10px;
  }

  & img {
 width: 15px;
 height: 15px;
 margin-left: 10px;
 @media (max-width: 468px) {
  width: 12px;
  height: 12px;
 }
  }
`;

const BlueText = styled.span`
  color: rgba(0, 107, 214, 1);
`;

const HeroImageWrapper = styled.div`
  width: 42%;
  margin-left: 20px;

  @media (max-width: 991px) {
    width: 100%;
  }
  @media (max-width: 468px) {
    margin-left: 0px;
    margin-top: 20px
  }
`;

const HeroImage = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  width: 100%;
`;

export default HeroSection;