import * as React from "react";
import styled from "styled-components";
import { Link } from 'react-router-dom';

const socialLinks = [
  {
    name: "Telegram",
    url: "https://t.me/Desi_Cow",
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/ff826b9dd5512bd7c171676fbc0e5a8e8d5228f5bda3b6aea37ae7d3cfd2a9b2?apiKey=df2e9b94f577479d8c34b9640987d692&",
  },
  {
    name: "Twitter",
    url: "https://twitter.com/Desi_Cow_",
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/6eea95970d5a4f0905e721e88933f9fed4accb2d4bca0213f06e1ca2e5714c55?apiKey=df2e9b94f577479d8c34b9640987d692&",
  },
  {
    name: "Uniswap",
    url: "https://app.uniswap.org/swap?exactField=&outputCurrency=0x0e99045438Fa058cd0EfA3e5dBF5E74bEdF4E3F0",
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/a4205681a69f9aa1224462b0a0e39bd74fed0a09b1281a96ad1074c025b912d5?apiKey=df2e9b94f577479d8c34b9640987d692&",
  },
  {
    name: "Dex Tool",
    url: "https://www.dextools.io/app/en/ether/pair-explorer/0x272914a8bf40d24aa4b6e04a1dae12194922bc19?t=1715003709278",
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/9a32f1e3aa7e677ccd6dd1015363ea184266d832ca228723cecfcfa648e3d895?apiKey=df2e9b94f577479d8c34b9640987d692&",
  },
];

function HeaderSection() {

  const handleSocialLinkClick = (url) => {
    window.open(url, '_blank'); // Open the URL in a new tab
  };

  return (
    <Header>
      <HeaderContent>
        <Link to="/">
          <LogoWrapper>
            <LogoImage src="https://cdn.builder.io/api/v1/image/assets/TEMP/21f3523e954285b0367ebd5578e1e0541fb7f83455288669e700ca865133321a?apiKey=df2e9b94f577479d8c34b9640987d692&" alt="$DESI logo" />
            <LogoText>$DESI</LogoText>
          </LogoWrapper>
        </Link>
        <SocialLinksWrapper>
          {socialLinks.map((link, index) => (
            <SocialLink key={index} onClick={() => handleSocialLinkClick(link.url)}>
              <SocialLinkInner>
                <SocialIcon src={link.icon} alt={`${link.name} icon`} />
                <SocialText>{link.name}</SocialText>
              </SocialLinkInner>
            </SocialLink>
          ))}
        </SocialLinksWrapper>
      </HeaderContent>
    </Header>
  );
}

export default HeaderSection;

const Header = styled.header`
  background-color: #000;
  color: #fff;
  padding: 16px 60px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
  @media (max-width: 468px) {
    width: 100%;
    padding: 0px;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  max-width: 1047px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin: 0 auto;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
  font-size: 13px;
  font-weight: 600;
  white-space: nowrap;
  @media (max-width: 991px) {
    white-space: initial;
  }
  @media (max-width: 468px) {
    padding-left: 20px;
    padding-top: 20px
  }
`;

const LogoImage = styled.img`
  width: 38px;
  aspect-ratio: 1;
  object-fit: contain;
`;

const LogoText = styled.span`
font-family: "Laila", serif;
  // font-family: "Laila", serif;
`;

const SocialLinksWrapper = styled.div`
  display: flex;
  gap: 20px;
  font-size: 11px;
  font-weight: 500;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
  @media (max-width: 468px) {
    gap: 10px;
    padding-left: 15px;
  }
`;

const SocialLink = styled.div`
cursor: pointer;
background: linear-gradient(to right, #006BD6, #E5A98D);
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 1px;
  border-radius: 15.5px;
  // border: 0.8px solid #006bd6;
  white-space: nowrap;
  @media (max-width: 991px) {
    white-space: initial;
  }
  @media (max-width: 468px) {
   margin-bottom: 15px
  }
`;

const SocialLinkInner = styled.a`
background: #000000;
display: flex;
align-items: center;
gap: 7px;
padding: 5px 12px;
border-radius: 15.5px;
// border: 0.8px solid #006bd6;
white-space: nowrap;
@media (max-width: 991px) {
  white-space: initial;
}
@media (max-width: 468px) {
  gap: 5px;
padding: 5px 7px;
}
`;
const SocialIcon = styled.img`
  width: 16px;
  aspect-ratio: 1;
  object-fit: contain;
  @media (max-width: 468px) {
    width: 14px;
  }
`;

const SocialText = styled.span`
  font-family: "Laila", serif;
`;