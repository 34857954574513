import * as React from "react";
import styled from "styled-components";

const PoweredByImages = [
  "https://cdn.builder.io/api/v1/image/assets/TEMP/d6f47398a88e69973401b38b4c74e2aa6b25898adf6a849e2bee3fe851c81fa6?apiKey=4ea074c2172a4f308a4b8ec9272610d2&",
  "https://cdn.builder.io/api/v1/image/assets/TEMP/7dc8e4ad3addca728b18173d0afce80449c9ffe55f2af93f68dcba118c3c81a9?apiKey=4ea074c2172a4f308a4b8ec9272610d2&",
  "https://cdn.builder.io/api/v1/image/assets/TEMP/ad23014618d5e1fd970b0d1a1d1cbf14a5c03d23569409eaa2030309e533d61e?apiKey=4ea074c2172a4f308a4b8ec9272610d2&",
];

const PartnerImages = [
  "https://cdn.builder.io/api/v1/image/assets/TEMP/03cc8c62edaa586152d60005b1fcb425f83ec1fb3c90411271d209cce6cee72f?apiKey=4ea074c2172a4f308a4b8ec9272610d2&",
  "https://cdn.builder.io/api/v1/image/assets/TEMP/4089109fb2883be29185471aca3fcff06bd121e660ec76ad18c79c4ff32ec9a1?apiKey=4ea074c2172a4f308a4b8ec9272610d2&",
  "https://cdn.builder.io/api/v1/image/assets/TEMP/e74fb51c025b6e5cf18f10f154e753232eb8f6ab647e9909eeb382c3b17bbd7b?apiKey=4ea074c2172a4f308a4b8ec9272610d2&",
  "https://cdn.builder.io/api/v1/image/assets/TEMP/750fe5f33554cb331539a946bb28a94acaef3a333eb7e81a7bc160da3f5850d4?apiKey=4ea074c2172a4f308a4b8ec9272610d2&",
];

function DesiCoinSection() {
  return (
    <SectionWrapper>
      <BackgroundImage src="https://cdn.builder.io/api/v1/image/assets/TEMP/008eff1e3b2874f605d91fa47b168b3fd5f712a5e57f4bae90a0789800869f71?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" alt="" />
      <ContentWrapper>
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/86834d6f234a552dc7fc20ff6fed8fc445a73815bc5ac85d2e7e1234fd8f1aea?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" alt="" />
        <BottomContent>
          <PoweredBySection>
            <Title>$DESI Coin is Powered by</Title>
            <PoweredByLogos>
              {PoweredByImages.map((src, index) => (
                <PoweredByLogo key={index} src={src} alt="" />
              ))}
            </PoweredByLogos>
            <PartnerLogos>
              {PartnerImages.map((src, index) => (
                <PartnerLogo key={index} src={src} alt="" />
              ))}
            </PartnerLogos>
          </PoweredBySection>
          <PartnerLogo
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/8490df459b463fa4af9699ebd7aaecbbbb904edbb65d42b3ef0a8d8dc805b871?apiKey=4ea074c2172a4f308a4b8ec9272610d2&"
            alt=""
            style={{ marginTop: "97px", alignSelf: "start" }}
          />
        </BottomContent>
      </ContentWrapper>
    </SectionWrapper>
  );
}

const SectionWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 60px;
  position: relative;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  min-height: 963px;
  width: 100%;
  max-width: 963px;
  padding-top: 80px;
  align-items: start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const BottomContent = styled.div`
  position: relative;
  overflow: hidden;
  z-index: 10;
  display: flex;
  min-height: 450px;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 465px 0 -32px;
  padding: 69px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin: 40px 0 10px;
    padding: 0 20px;
    flex-wrap: wrap;
  }
`;

const PoweredBySection = styled.div`
  border-radius: 200px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.29) 0%,
    rgba(0, 0, 0, 0.32) 70.5%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  flex-basis: 0;
  width: fit-content;
  padding: 6px 60px 0;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const Title = styled.h2`
  color: #fff;
  text-align: center;
  font: 500 40px "Laila", serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const PoweredByLogos = styled.div`
  display: flex;
  margin-top: 50px;
  width: 614px;
  max-width: 100%;
  gap: 20px;
  justify-content: space-between;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    margin-top: 40px;
  }
`;

const PoweredByLogo = styled.img`
  aspect-ratio: 1.89;
  object-fit: contain;
  object-position: center;
  width: 165px;
  max-width: 100%;
`;

const PartnerLogos = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: 36px;
  gap: 20px;
  justify-content: space-between;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const PartnerLogo = styled.img`
  aspect-ratio: 1.92;
  object-fit: contain;
  object-position: center;
  width: 147px;
  max-width: 100%;
`;

export default DesiCoinSection;