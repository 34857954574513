import * as React from "react";
import styled from "styled-components";

const taxData = [
    {
        title: "Buy Tax",
        value: "4.5%",
        imageSrc: "/img/line.png",
    },
    {
        title: "Sell Tax",
        value: "5.75%",
        imageSrc: "/img/line.png",
    },
    {
        title: "Transfer Tax",
        value: "1%",
        imageSrc: "/img/line.png",
    },
];

function MyComponent() {
    return (
        <Container>
            <BackgroundImage loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c3510fe7722932e7ac4415256f4b998e2014a675deb2cf548c7760083a25c274?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" />
            <ContentWrapper>
                <TaxDistributionSection>
                    <Title style={{ }}>Taxes</Title>
                    <TaxBreakdownContent>
                        <TaxBreakdown>
                            {taxData.map((item, index) => (
                                <TaxItem key={index}>
                                    <TaxTitle>
                                        {item.title}
                                        <br></br>
                                        <TaxValue>{item.value}</TaxValue>
                                    </TaxTitle>
                                    <TaxImage loading="lazy" src={item.imageSrc} />
                                </TaxItem>
                            ))}
                        </TaxBreakdown>
                    </TaxBreakdownContent>
                </TaxDistributionSection>
                <Title style={{ width: '100%' }}>Tax DISTRIBUTION</Title>
                <TaxDistributionTitle>
                    <BuyBackPercentage>
                        <BoldText>25% for Buy Back:</BoldText>
                        <Text>Ensuring price stability</Text>
                        <Text>and boosting investor</Text>
                        <Text>confidence.</Text>
                    </BuyBackPercentage>
                    <TeamPercentage>
                        <BoldText>40% to the Team:</BoldText>
                        <Text>Supporting ongoing</Text>
                        <Text>development and project</Text>
                        <Text>management.</Text>
                    </TeamPercentage>
                </TaxDistributionTitle>
                <Divider loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/398166eef92c6a369c10e9a2669739aca75d3ac3205d1a9391e04bb259025cd9?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" />
                <BottomPercentages>
                    <LiquidityPercentage>
                        <BoldText>25% to Adding Liquidity:</BoldText>
                        <Text>Enhancing market stability</Text>
                        <Text>and maintaining liquidity.</Text>
                    </LiquidityPercentage>
                    <CowShelterPercentage>
                        <BoldText>10% to Gau-shala</BoldText>
                        <BoldText>(Cow Shelters):</BoldText>
                        <Text>Supporting cow shelters to</Text>
                        <Text>ensure the well-being of</Text>
                        <Text>India's sacred cows.</Text>
                    </CowShelterPercentage>
                </BottomPercentages>
            </ContentWrapper>
        </Container>
    );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 650px;
  padding: 67px 60px;
  overflow: hidden;

  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 911px;
  max-width: 100%;
  margin-top: 14px;
`;

const TaxDistributionSection = styled.section`
  width: 100%;
  max-width: 100%;
    margin-bottom: 100px;
    @media (max-width: 468px) {
        margin-bottom: 30px;
    }
`;

const TaxDistributionContent = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 991px) {
    flex-direction: column;
    gap: 0;
  }
`;

const BuyBackPercentage = styled.div`
  width: 25%;
//   margin-top: 290px;
  color: #c2c2c2;
  text-align: center;
  font: 400 15px "Laila", serif;

  @media (max-width: 991px) {
    width: 100%;
    margin-top: 40px;
  }
  @media (max-width: 468px) {
width: 30%;
font: 400 11px "Laila", serif;
  }
`;

const TaxBreakdownWrapper = styled.div`
  width: 75%;
  margin-left: 20px;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

const TaxBreakdownContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const TaxBreakdown = styled.div`
  display: flex;
  gap: 20px;
  color: #c2c2c2;
  font: 400 15px "Laila", serif;

  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const TaxItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

const TaxTitle = styled.div`
  font-family: "Laila", serif;
`;

const TaxValue = styled.span`
  font-size: 24px;
`;

const TaxImage = styled.img`
  width: 111px;
  margin-top: 15px;
  stroke: #050505;
  stroke-width: 3px;
  aspect-ratio: 33.33;
  object-fit: auto;
  object-position: center;
`;

const TaxDistributionTitle = styled.div`
  display: flex;
  width: 75%;
  max-width: 100%;
//   margin: 153px 21px 0 0;
  align-self: start;
  font-weight: 700;
  justify-content: space-between;

  @media (max-width: 991px) {
    margin: 40px 10px 0 0;
  }

  @media (max-width: 468px) {
    width: 85%;
  }
`;

const Title = styled.h2`
  color: #fff;
  text-transform: uppercase;
  font: 24px "Laila", serif;
  text-align: center; 
  padding-bottom: 50px;
  @media (max-width: 468px) {
    padding-bottom: 0px;
  }
`;

const TeamPercentage = styled.div`
    width: 25%;
  color: #c2c2c2;
  text-align: center;
  font: 400 15px "Laila", serif;

  @media (max-width: 991px) {
    margin-top: 40px;
  }
  @media (max-width: 468px) {
    width: 30%;
    font: 400 11px "Laila", serif;
      }
`;

const Divider = styled.img`
  width: 863px;
  max-width: 100%;
  margin-top: 11px;
  aspect-ratio: 33.33;
  object-fit: auto;
  object-position: center;
`;

const BottomPercentages = styled.div`
  display: flex;
  justify-content: space-between;
  width: 677px;
  max-width: 100%;
  margin-top: 19px;
  gap: 20px;
  color: #c2c2c2;
  text-align: center;
  font: 400 15px "Laila", serif;
  align-self: end;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const LiquidityPercentage = styled.div`
  font-family: "Laila", serif;
  @media (max-width: 468px) {
    width: 30%;
    font: 400 11px "Laila", serif;
      }
`;

const CowShelterPercentage = styled.div`
  font-family: "Laila", serif;
  @media (max-width: 468px) {
    width: 30%;
    font: 400 11px "Laila", serif;
      }
`;

const BoldText = styled.span`
  font-weight: 700;
`;

const Text = styled.span`
  display: block;
`;

export default MyComponent;